<script>
export default {
    data() {
        return {
            title: 'Confirmation',
            message: '',
            confirmedCallback: null,
            show: false,
        };
    },
    methods: {
        showModal(message, confirmedCallback) {
            this.message = message;
            this.confirmedCallback = confirmedCallback;
            this.show = true;
        },

        closeModal() {
            this.show = false;
        }
    }
}
</script>

<template>
    <b-modal v-model="show">
        <template #modal-title>
            {{ title }}
        </template>
        <template #modal-footer>
            <button type="button" class="btn btn-primary" v-on:click="confirmedCallback">Confirm</button>
        </template>
        <div>
            <p v-html="message" style="white-space:pre-line !important; overflow-wrap: break-word;"></p>
        </div>
    </b-modal>
</template>