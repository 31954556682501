<script>
import Layout from "../../layouts/main";
import PageHeader from "@/components/page-header";

import ConfirmationModal from "@/components/modals/confirmation-modal";
import MessageModal from "@/components/modals/message-modal";

import ListingTable from "@/components/listings/listing-table";

export default {
    components: {
        Layout,
        PageHeader,
        ConfirmationModal,
        MessageModal,
        ListingTable,
    },
    data() {
        return {
            title: "Service",
            items: [
                {
                    text: "List"
                },
            ],
            filter: {
                duration: '',
                status: 1,
            },
            statusOptions: [
                { name: 'All', value: 2 },
                { name: 'Active', value: 1 },
                { name: 'Inactive', value: 0 },
            ],
            fields: [
                { key: "name", sortable: true, label: "Service" },
                { key: "status", label: "Status" },
                { key: "action" }
            ],
        };
    },
    methods: {
        //
    }
};
</script>

<template>
    <Layout>
        <PageHeader :title="title" :items="items" />
        <ConfirmationModal ref="confirmationModal" />
        <MessageModal ref="messageModal" />
        <div class="row">
            <div class="col-lg-12">
                <div class="card">
                    <div class="card-body pt-0" style="position: relative;">
                        <ListingTable ref="listingTable" :fields="fields" path="field_management/service" listName="services">
                            <template v-slot:cell(status)="row">
                                <div class="font-size-12" :class="{
                                    'status-red': parseInt(`${row.value}`) === 0,
                                    'status-green': parseInt(`${row.value}`) === 1,
                                }">{{ parseInt(row.value) === 1 ? 'Active' : 'Inactive' }}</div>
                            </template>
                            <template v-slot:cell(action)="{ item }">
                                <router-link class="mr-3 text-primary" :to="{name: 'Service Form', params: {id: item.id}}">
                                    <i class="mdi mdi-pencil font-size-18" v-b-tooltip.hover title="Edit"></i>
                                </router-link>
                            </template>
                        </ListingTable>
                    </div>
                </div>
            </div>
        </div>
    </Layout>
</template>