<script>
export default {
    data() {
        return {
            title: 'Message',
            message: '',
            show: false,
        };
    },
    methods: {
        showModal(message) {
            this.message = message;
            this.show = true;
        },

        closeModal() {
            this.show = false;
            location.reload();
        },

        doSometing() {
            this.$emit('CloseBtn', true);
        }
    },
    mounted() {
        
    }
}
</script>

<template>
    <b-modal v-model="show" @hide="doSometing">
        <template #modal-title>
            {{ title }}
        </template>
        <template #modal-footer>
            <button type="button" class="btn btn-primary" v-on:click="closeModal">OK</button>
        </template>
        <div>
            <p>{{ message }}</p>
        </div>
        <!-- /.modal-dialog -->
    </b-modal>
    <!-- /.modal -->
</template>